@import "https://cdn.jsdelivr.net/npm/reset-css@5.0.1/reset.min.css";

* {
  box-sizing: border-box;
}
* {
  font-family: "Noto Sans KR", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
html,
body {
  height: 100%;
  background-color: #f8f9fa;
  font-family: Noto Serif KR;
  font-size: 14px;
  color: black;
}

body {
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: 700;
  font-size: 42px;
  line-height: 62px;
  color: #333;
  padding-bottom: 1.1rem;
}

h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 52px;
  color: #333;
}

h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.45em;
  margin-top: 15 * 2;
  margin-bottom: 24;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
}

h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.45em;
  margin-top: 15 * 2;
  margin-bottom: 20;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
}

h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.45em;
  margin-top: 15 * 2;
  margin-bottom: 15;
}

p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.45em;
  padding-bottom: 1.2rem;
  word-break: keep-all;
}

li {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.45rem;
  padding-bottom: 0.8rem;
  word-break: keep-all;
}

ol {
  margin-left: 20px;
}

input {
  all: unset;
  box-sizing: border-box;
  appearance: none;
}

button {
  background-color: white;
  color: black;
}

a {
  text-decoration: none;
  color: inherit;
}

form {
  width: 100%;
}

hr {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  background-color: black;
  width: 100%;
}

.margin-minus-5 {
  margin-bottom: -5px;
}

.bg-gray {
  background-color: #f7fafb;
}

.center-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.left-middle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.valign-middle {
  display: flex;
  align-items: center;
}

.bg-black {
  background: black;
}

.font-bold {
  font-weight: 700;
}

.font-white {
  color: white;
}

.font-gray {
  color: rgb(153, 153, 153);
}

.font-link {
  color: #04aaff;
}

.cursor-pointer {
  cursor: pointer;
}

.app {
  text-align: center;
  font-family: NotoSansKR-Medium;
}

.content {
  flex: 1 0 auto;
  padding: 10px;
}

.nweet-input::placeholder {
  color: white;
  opacity: 0.9;
}

.loginForm {
  width: 100%;
  max-width: 320px;
}

.formInput {
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid black;
  text-align: center;
  background-color: white;
  color: black;
}

.formBtn {
  cursor: pointer;
  width: 100%;
  padding: 7px 20px;
  text-align: center;
  color: white;
  border-radius: 20px;
  background-color: #04aaff;
  cursor: pointer;
}

.cancelBtn {
  cursor: pointer;
  background-color: tomato;
}

/* Profile Page */

.profileForm {
  border-bottom: 1px solid rgba(255, 255, 255, 0.9);
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logOut {
  margin-top: 50px;
}

/* Nwitter Factory */

.factoryForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.factoryInput__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.factoryInput__input {
  flex-grow: 1;
  height: 40px;
  padding: 0px 20px;
  color: white;
  border: 1px solid #04aaff;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
}

.factoryInput__arrow {
  position: absolute;
  right: 0;
  background-color: #04aaff;
  height: 40px;
  width: 40px;
  padding: 10px 0px;
  text-align: center;
  border-radius: 20px;
  color: white;
}

.factoryInput__label {
  color: #04aaff;
  cursor: pointer;
}

.factoryInput__label span {
  margin-right: 10px;
  font-size: 16px;
}

.factoryForm__attachment {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.factoryForm__attachment img {
  height: 80px;
  width: 80px;
  border-radius: 40px;
}

.factoryForm__clear {
  color: #04aaff;
  cursor: pointer;
  text-align: center;
}

.factoryForm__clear span {
  margin-right: 10px;
  font-size: 12px;
}

/* Nweet */

.nweetEdit .formBtn {
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 5px;
}

.nweet {
  margin-bottom: 20px;
  background-color: white;
  width: 100%;
  max-width: 320px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.8);
}

.nweet h4 {
  font-size: 14px;
}

.nweet {
  right: -10px;
  top: 20px;
  position: absolute;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-top: 10px;
}

.nweet__actions {
  position: absolute;
  right: 10px;
  top: 10px;
}

.nweet__actions span {
  cursor: pointer;
}

.nweet__actions span:first-child {
  margin-right: 10px;
}

/* Auth */

.authContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.authBtns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 320px;
}

.authBtn {
  cursor: pointer;
  border-radius: 20px;
  border: none;
  padding: 10px 0px;
  font-size: 12px;
  text-align: center;
  width: 150px;
  background: white;
  cursor: pointer;
}

/* AuthForm */

.authInput {
  max-width: 320px;
  width: 100%;
  padding: 10px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  font-size: 12px;
  color: black;
}

.authSubmit {
  text-align: center;
  background: #04aaff;
  color: white;
  margin-top: 10;
  cursor: pointer;
}

.authError {
  color: tomato;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
}

.authSwitch {
  color: #04aaff;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 30px;
  display: block;
  font-size: 16px;
  text-decoration: underline;
}

.footer {
  margin-top: 1rem;
  padding: 1rem;
  flex-shrink: 0;
  text-align: center;
  background-color: #f5f5f6;
}

.icon {
  padding: 5px 10px;
}
.icon:hover {
  transform: rotate(45deg);
  transition: all 0.25s ease-in-out;
}

@media (max-width: 768px) {
  .video-width {
    width: 100%;
    height: 300px;
  }

  .indentation-snd-col {
    margin-left: 0px;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 0.1rem auto;
    border-radius: 4px;
    width: 100%;
    background: #fad934;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #6568f4;
    transition: 250ms;
  }

	.about-header {
		height: 330px;
	}
  
	.about-title {
		font-size: 24px;
		font-weight: 700;
		word-break: keep-all;
    line-height: 40px;
    text-shadow: pink 1px 0 10px;
	}
}

@media (min-width: 769px) {
  .indentation-snd-col {
    margin-left: 25px;
  }

  .video-width {
    width: 560px;
    height: 315px;
  }

  .about-header {
		height: 430px;
	}

  .about-title {
		font-size: 42px;
		font-weight: 700;
		word-break: keep-all;
    line-height: 70px;
    text-shadow: pink 1px 0 10px;
	}
}

.container-890 {
  max-width: 890px;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
